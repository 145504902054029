import { GqlService } from '../modules/content/service/gql.service';
import { Injectable } from '@angular/core';
import { AppointmentFieldsInputType } from '@libs/types/src/scheduler/input-type/scheduler.input-type';
import {
    schedulerAppointmentFrag,
    schedulerVoucherPrescribedMedicalProceduresFrag,
} from './types/scheduler.gql-types';
import { GridInput } from '@it2go/data-grid';
import { filterFrag } from './types/filter.types';

@Injectable()
export class SchedulerGqlService extends GqlService {
    public schedulerBatchUpdate(input: AppointmentFieldsInputType) {
        return this.mutate(
            `
                mutation($input: AppointmentFieldsInputType!) {
                    scheduler {
                        batchUpdate(input: $input) {
                            ${schedulerAppointmentFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getSchedulerEvents() {
        return this.query(
            `
                query {
                    scheduler {
                        getEvents {
                            ${schedulerAppointmentFrag}
                        }
                    }
                }
            `,
            {},
        );
    }

    public getSchedulerVoucherPrescribedMedicalProcedures(input: GridInput) {
        return this.query(
            `
                query($input: GridInput) {
                    voucherPrescribedMedicalProcedure {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${schedulerVoucherPrescribedMedicalProceduresFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }
}
