import { addressFrag } from './common.types';
import { personFrag } from './person.types';
import { serviceUserFrag } from './user.types';
import { voucherPrescribedMedicalProcedureFrag } from './voucher.types';

export const performedProcedureFrag = `
    applications
    diagnosis {
        code
        name
        parentName
    }
    voucherPrescribedMedicalProcedureId
    voucherPrescribedMedicalProcedure {
        ${voucherPrescribedMedicalProcedureFrag}
    }
`;

export const performedProcedureGridItemFrag = `
    id
    code
    description
    applications
    folderId
    person {
        ${personFrag}
    }
    work {
        id
        type
        dateFrom
        dateTo
    }
    diagnosis {
        code
        name
        parentName
    }
    users {
        id
        name
        surname
        email
        titleAfterName
        titleBeforeName
    }
    expertise
    voucherPrescribedMedicalProcedureId
    voucherPrescribedMedicalProcedure {
        ${voucherPrescribedMedicalProcedureFrag}
    }
`;

export const performedProcedureGridItemSearchFrag = `
    ${performedProcedureGridItemFrag}
    serviceInfo {
        name
        color
    }
`;

export const workDataFrag = `
    workData {
        ... on WorkDataVisitObject {
            assistantId
            distanceFrom
            distanceTo
            id
            medication
            note
            state
            addressFrom {
                ${addressFrag}
            }
            addressTo{
                ${addressFrag}
            }
            voucherNumber
            contactType
            signed
            locked
            systolicPressure
            diastolicPressure
            pulse
            breath
            glycemia
            spo2
            pain
            weight
            pulseByBeats
            pulseByTouch
        }
        ... on WorkDataUnrealizedVisitObject {
            note
            comment
            voucherNumber
            assistantId
        }
        ... on WorkDataServiceRecordObject {
            note
            comment
        }
        ... on WorkDataResiduaryCareObject {
            data
            comment
            contactPerson
            state
            type
            relation
        }
        ... on WorkDataInterventionObject {
            id
            medication
            note
            state
            voucherNumber
            systolicPressure
            diastolicPressure
            pulse
            breath
            glycemia
            spo2
            pain
            weight
            pulseByBeats
            pulseByTouch
        }
        ... on WorkDataSpecialistRecordObject {
            id
            note
            comment
            contactType
            workType
            distance
        }
        ... on WorkDataDoctorRecordObject {
            id
            contactType
            state
            recordTimestamp
            distance
            note
            comment
            medication
            nextVisit
            signed
            locked
            systolicPressure
            diastolicPressure
            pulse
            breath
            glycemia
            spo2
            pain
            weight
            pulseByBeats
            pulseByTouch
        }
    }
`;

export const workAdditionalInfoFrag = `
    insuranceAbbr
    insuranceType
`;

export const workFrag = `
    id
    dateFrom
    dateTo
    folderId
    additionalInfo {
        ${workAdditionalInfoFrag}
    }
    workType
    ${workDataFrag}
    performedProcedures {
        ${performedProcedureFrag}
    }
`;

export const workGridItemFrag = `
    id
    dateFrom
    dateTo
    folderId
    serviceId
    person {
        ${personFrag}
    }
    users {
        ${serviceUserFrag}
    }
    additionalInfo {
        ${workAdditionalInfoFrag}
    }
    workType
    isBilled
    ${workDataFrag}
    files {
        fileId
        name
        path
    }
    performedProcedures {
        ${performedProcedureFrag}
    }
    folderFormData {
        folderForm {
            form {
                uniqueIdentifier
            }
        }
    }
`;

