import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { combineLatest, map } from 'rxjs';
import { isAllowed } from '../../../../store/global/global.selectors';
import { isSubitemActive } from '../../../shared/helpers/menu-subitem-active';
import { LayoutService } from '../../services/layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
})
export class MenuComponent {
    sidebarOptions$ = combineLatest([
        this.translate.stream([
            'sidebar.databoxes',
            'sidebar.sites',
            'sidebar.crm',
            'breadcrumbs.warnings',
            'sidebar.donors',
            'sidebar.donations',
            'sidebar.collections',
            'sidebar.summaryEmails',
            'sidebar.health-services',
            'sidebar.client-folders',
            'sidebar.settings',
            'sidebar.organizations',
            'sidebar.users',
            'sidebar.services',
            'sidebar.works',
            'sidebar.realizedWork',
            'sidebar.workVisits',
            'sidebar.workUnrealizedVisits',
            'sidebar.workDailyRecords',
            'sidebar.workServiceRecords',
            'sidebar.workInterventions',
            'sidebar.workDoctorRecords',
            'sidebar.workSpecialistRecords',
            'sidebar.performedProcedures',
            'sidebar.extramuralCare',
            'sidebar.workResiduaryCares',
            'sidebar.billing',
            'sidebar.billingCreateNew',
            'sidebar.billingRefused',
            'sidebar.billingUnbilledWork',
            'sidebar.billingAccountingOutput',
            'sidebar.billingCreatedBillings',
            'sidebar.files-manager',
            'sidebar.scheduler',
        ]),
        this.store.select(isAllowed('databox.read')),
        this.store.select(isAllowed('donation.read')),
        this.store.select(isAllowed('folder.read')),
        this.store.select(isAllowed('organization.update')),
        this.store.select(isAllowed('organization.create')),
        this.store.select(isAllowed('service.read')),
        this.store.select(isAllowed('service.create')),
        this.store.select(isAllowed('service.update')),
        this.store.select(isAllowed('user.create')),
        this.store.select(isAllowed('user.update')),
        this.store.select(isAllowed('collectionStatistics.read')),
        this.store.select(isAllowed('billing.read')),
        this.store.select(isAllowed('work.create')),
        this.store.select(isAllowed('doctorWork.create')),
        this.store.select(isAllowed('specialistWork.create')),
        this.store.select(isAllowed('work.read')),
        this.store.select(isAllowed('doctorWork.read')),
        this.store.select(isAllowed('specialistWork.read')),
    ]).pipe(
        map(
            ([
                 translated,
                 readDatabox,
                 readDonation,
                 readFolder,
                 updateOrganization,
                 createOrganization,
                 readService,
                 createService,
                 updateService,
                 createUser,
                 updateUser,
                 collectionStatisticsRead,
                 readBilling,
                 createWork,
                 createDoctorWork,
                 createSpecialistWork,
                 readWork,
                 readDoctorWork,
                 readSpecialistWork,
             ]) => {
                // TODO: get rid of deprecated isAllowed
                // const readDatabox = isAllowed['databox.read'];
                // const readDonation = isAllowed['donation.read'];
                // const readFolder = isAllowed['folder.read'];
                // const updateOrganization = isAllowed['organization.update'];
                // const readService = isAllowed['service.read'];
                // const updateService = isAllowed['service.update'];
                // const updateUser = isAllowed['user.update'];
                // const collectionStatisticsRead = isAllowed['collectionStatistics.read'];
                // const readBilling = isAllowed['billing.read'];
                const options = [];

                if (readDatabox) {
                    options.push({
                        label: translated['sidebar.sites'],
                        icon: 'pi pi-pw pi-file',
                        visible: true,
                        separator: false,
                        items: [
                            {
                                label: translated['sidebar.databoxes'],
                                icon: 'pi pi-fw pi-external-link',
                                routerLink: '/databoxes/messages',
                                visible: true,
                                separator: false,
                            },
                        ],
                    });
                }

                if (readDonation || collectionStatisticsRead) {
                    options.push({
                        label: translated['sidebar.crm'],
                        icon: 'pi pi-pw pi-file',
                        visible: true,
                        separator: false,
                        items: [
                            readDonation ? {
                                label: translated['breadcrumbs.warnings'],
                                icon: 'pi pi-fw pi-exclamation-triangle',
                                routerLink: '/crm/warnings',
                                visible: true,
                                separator: false,
                            } : null,
                            readDonation ? {
                                label: translated['sidebar.donors'],
                                icon: 'pi pi-fw pi-external-link',
                                routerLink: '/crm/donors',
                                visible: true,
                                separator: false,
                            }: null,
                            readDonation ? {
                                label: translated['sidebar.donations'],
                                icon: 'pi pi-fw pi-external-link',
                                routerLink: '/crm/donations',
                                visible: true,
                                separator: false,
                            }: null,
                            (readDonation || collectionStatisticsRead) ? {
                                label: translated['sidebar.collections'],
                                icon: 'pi pi-fw pi-external-link',
                                routerLink: '/crm/collections',
                                visible: true,
                                separator: false,
                            }: null,
                            readDonation ? {
                                label: translated['sidebar.summaryEmails'],
                                icon: 'pi pi-fw pi-external-link',
                                routerLink: '/crm/summary-emails',
                                visible: true,
                                separator: false,
                            }: null,
                            readDonation ? {
                                label: translated['sidebar.settings'],
                                icon: 'pi pi-fw pi-external-link',
                                routerLink: '/crm/settings',
                                visible: true,
                                separator: false,
                            }: null,
                        ].filter((it) => it),
                    });
                }

                if (readFolder) {
                    const sublinks: any[] = [
                        {
                            label: translated['sidebar.client-folders'],
                            icon: 'pi pi-fw pi-folder',
                            routerLink: '/content/folders',
                            visible: true,
                            separator: false,
                        },
                    ];
                    sublinks.push({
                        label: translated['sidebar.files-manager'],
                        icon: 'pi pi-fw pi-server', // TODO: change icon
                        routerLink: '/content/files',
                        visible: true,
                        separator: false,
                    });

                    if (createWork || createDoctorWork || createSpecialistWork) {
                        sublinks.push({
                            label: translated['sidebar.works'],
                            icon: 'pi pi-fw pi-plus',
                            routerLink: '/content/works',
                            visible: true,
                            separator: false,
                        });
                    }
                    if (readWork || readDoctorWork || readSpecialistWork) {
                        const workItems = [];
                        if (readWork) {
                            workItems.push({
                                    label: translated['sidebar.workVisits'],
                                    routerLink:
                                        '/content/realized-work/list/visit',
                                    visible: true,
                                    separator: false,
                                },
                                {
                                    label: translated['sidebar.performedProcedures'],
                                    routerLink:
                                        '/content/realized-work/list/performed-procedures',
                                    visible: true,
                                    separator: false,
                                },
                                {
                                    label: translated['sidebar.workUnrealizedVisits'],
                                    routerLink:
                                        '/content/realized-work/list/unrealized-visit',
                                    visible: true,
                                    separator: false,
                                },
                                {
                                    label: translated['sidebar.workDailyRecords'],
                                    routerLink:
                                        '/content/realized-work/list/daily-record',
                                    visible: true,
                                    separator: false,
                                },
                                {
                                    label: translated['sidebar.workServiceRecords'],
                                    routerLink:
                                        '/content/realized-work/list/service-record',
                                    visible: true,
                                    separator: false,
                                },
                                {
                                    label: translated['sidebar.extramuralCare'],
                                    routerLink:
                                        '/content/realized-work/list/extramural-care',
                                    visible: true,
                                    separator: false,
                                },
                                {
                                    label: translated['sidebar.workResiduaryCares'],
                                    routerLink:
                                        '/content/realized-work/list/residuary-care',
                                    visible: true,
                                    separator: false,
                                },
                                {
                                    label: translated['sidebar.workInterventions'],
                                    routerLink:
                                        '/content/realized-work/list/intervention',
                                    visible: true,
                                    separator: false,
                                });
                        }
                        if (readDoctorWork) {
                            workItems.push({
                                label: translated['sidebar.workDoctorRecords'],
                                routerLink:
                                    '/content/realized-work/list/doctor-record',
                                visible: true,
                                separator: false,
                            });
                        }
                        if (readSpecialistWork) {
                            workItems.push({
                                label: translated['sidebar.workSpecialistRecords'],
                                routerLink:
                                    '/content/realized-work/list/specialist-record',
                                visible: true,
                                separator: false,
                            });
                        }

                        sublinks.push({
                            label: translated['sidebar.realizedWork'],
                            icon: 'pi pi-pw pi-briefcase',
                            visible: true,
                            separator: false,
                            items: workItems,
                        });

                        sublinks.push({
                            label: translated['sidebar.scheduler'],
                            icon: 'pi pi-pw pi-calendar-plus',
                            routerLink: '/content/scheduler',
                            visible: true,
                            separator: false,
                        });
                    }
                    if (readBilling) {
                        sublinks.push({
                            label: translated['sidebar.billing'],
                            icon: 'pi pi-pw pi-money-bill',
                            visible: true,
                            separator: false,
                            items: [
                                {
                                    label: translated[
                                        'sidebar.billingCreateNew'
                                        ],
                                    routerLink: '/content/billing/create',
                                    visible: true,
                                    separator: false,
                                },
                                {
                                    label: translated['sidebar.billingRefused'],
                                    routerLink: '/content/billing/refused',
                                    visible: true,
                                    separator: false,
                                },
                                {
                                    label: translated[
                                        'sidebar.billingUnbilledWork'
                                        ],
                                    routerLink: '/content/billing/unbilled',
                                    visible: true,
                                    separator: false,
                                },
                                {
                                    label: translated[
                                        'sidebar.billingAccountingOutput'
                                        ],
                                    routerLink: '/content/billing/output',
                                    visible: true,
                                    separator: false,
                                },
                                {
                                    label: translated[
                                        'sidebar.billingCreatedBillings'
                                        ],
                                    visible: true,
                                    separator: false,
                                    items: [
                                        // TODO: namapovat z nactenych pojistoven
                                        {
                                            label: '111',
                                            routerLink:
                                                '/content/billing/created/111',
                                            visible: true,
                                            separator: false,
                                        },
                                        {
                                            label: '205',
                                            routerLink:
                                                '/content/billing/created/205',
                                            visible: true,
                                            separator: false,
                                        },
                                    ],
                                },
                            ],
                        });
                    }

                    options.push({
                        label: translated['sidebar.health-services'],
                        icon: 'pi pi-pw pi-heart',
                        visible: true,
                        separator: false,
                        items: sublinks,
                    });
                }
                if (updateOrganization || updateUser || updateService || createOrganization || createService) {
                    const settingLinks = [];
                    if (updateOrganization || createOrganization) {
                        settingLinks.push({
                            label: translated['sidebar.organizations'],
                            routerLink: '/content/organizations',
                            visible: true,
                            separator: false,
                        });
                    }
                    if (updateService || createService) {
                        settingLinks.push({
                            label: translated['sidebar.services'],
                            routerLink: '/content/services',
                            visible: true,
                            separator: false,
                        });
                    }
                    if (updateUser || createUser) {
                        settingLinks.push({
                            label: translated['sidebar.users'],
                            routerLink: '/content/users',
                            visible: true,
                            separator: false,
                        });
                    }

                    options.push({
                        label: translated['sidebar.settings'],
                        icon: 'pi pi-pw pi-cog',
                        visible: true,
                        separator: false,
                        items: settingLinks,
                    });
                }

                return options;
            },
        ),
    );

    constructor(
        public layoutService: LayoutService,
        public router: Router,
        private translate: TranslateService,
        private readonly store: Store,
    ) {
    }

    isItemActive(item: MenuItem): boolean {
        return (
            this.router.url !== '/' &&
            !this.layoutService.isStatic() &&
            !this.layoutService.isOverlay() &&
            (this.router.url.includes(item.routerLink) ||
                (!!item.items?.length &&
                    isSubitemActive(item.items, this.router.url)))
        );
    }
}
